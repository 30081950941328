import React from "react";

export default function CompanyRequisites(props: any) {
    return (
        <div className='order-requisites' style={{marginTop: 24, fontSize: 16, color: '#6a6a6a'}}>
            ИП Сырвасов Александр Михайлович
            ИНН 860223472624
            ОГРНИП 311860202400013<br/>
            Почтовый адрес: 628412, Россия, Тюменская область, ХМАО-Югра, г. Сургут, ул. Республики д.4, ОПС-12, "До
            востребования"<br/>
            <br/>
            ИП Кырнич Степан Дмитриевич
            ИНН 860232367739
            ОГРНИП 324861700067668<br/>
            Почтовый адрес: 628412, Россия, Тюменская область, ХМАО-Югра, г. Сургут, ул. Республики д.4, ОПС-12, "До
            востребования"
        </div>
    )
};
