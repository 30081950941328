import React from "react";
import {verifyOrder} from "./default-container";
import ViewOrderDate from "../@share/date";
import ViewOrderTime from "../@share/time";
import ViewOrderGuestsCounter from "../@share/guests";
import ViewOrderTableSelector from "../@share/table";
import ViewOrderComment from "../@share/comment";
import ViewCompanyInfo from "../@share/company-info";
import OrderMap from "../map/map";
import OrderDuration from "../@share/order-duration";
import {IOrderOptions} from "../../order";
import {IOrder} from "../../../../types/order";
import CompanyRequisites from "../@share/company-requisites";

export default function ViewDefault(props: IOrderOptions) {
    const isDisabled = verifyOrder(props.order as IOrder, props.companyInfo.maxGuestCounter) ? '' : 'is-disabled';

    // Если кнопка не активна - можем нажать продолжить
    function  clickHandler() {
        if (isDisabled === '') props.setViewPort('infoUser');
    }

    return (
        <div className="order">
            <div className="order__title">Забронировать место</div>

            <ViewCompanyInfo {...props.companyInfo}/>

            <div className="order__body">

                <ViewOrderDate {...props}/>

                <ViewOrderTime {...props}/>

                <ViewOrderGuestsCounter {...props}/>

                <OrderDuration {...props}/>

                <ViewOrderTableSelector {...props}/>

                <ViewOrderComment {...props}/>

                {    // @ts-ignore
                     (props.viewPort === 'map') && <OrderMap {...props}/>}

                <CompanyRequisites />

            </div>

            <div className="order__save-wrapper">
                <div className={"order__save " + isDisabled} onClick={() => clickHandler()}>Продолжить</div>
            </div>
        </div>
    )
}
