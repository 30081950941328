import React from "react";
import {verifyOrder} from "../default/default-container";
import {FreeTablesModeInfo} from "./free-tables-view";

import './free-tables.scss';
import ViewOrderDate from "../@share/date";
import ViewOrderTime from "../@share/time";
import ViewCompanyInfo from "../@share/company-info/company-info";
import CompanyRequisites from "../@share/company-requisites";

export default function FreeTablesMode(props  : any) {
    const isDisabled = verifyOrder(props.order, props.companyInfo.maxGuestCounter) ? '' : 'is-disabled';

    return (
        <div className="order">
            <div className="order__title">Забронировать место</div>

            <ViewCompanyInfo {...props.companyInfo}/>

            <div className="order__body">
                <ViewOrderDate {...props}/>

                <ViewOrderTime {...props} />

                <FreeTablesModeInfo {...props}/>

                <CompanyRequisites />
            </div>

            <div className="order__save-wrapper">
                <div className={"order__save " + isDisabled}>Продолжить</div>
            </div>
        </div>
    )
}
